<template>
  <div class="modal profile-modal">
    <div 
      class="overlay" 
      @click="$parent.closeProfileModal"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img 
              src="./../images/close.svg"
              class="close" 
              @click="$parent.closeProfileModal" 
            >
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="profile-modal-left">
                  <div class="purchases">
                    <div class="desc desc-main">
                      <b>{{ $t('Purchases') }}</b>
                    </div>
                    <div class="purchases-list">
                      <div class="purchases__item">
                        <div class="desc">
                          {{ $t('Count:') }}
                        </div>
                        <div class="desc">
                          <b>{{ $parent.orderHistory.length }}</b>
                        </div>
                      </div>
                      <div class="purchases__item">
                        <div class="desc">
                          {{ $t('Total value:') }}
                        </div>
                        <div class="desc">
                          <b>{{ $parent.currency }} {{ totalSum }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul class="profile-nav">
                    <li 
                      v-for="(item, i) in nav"
                      :key="i" 
                      :class="['profile-nav__item desc', {'active': i == activeNavItem}]"
                      @click="setActiveNavItem(i)"
                    >
                      <b>{{ item }}</b>
                    </li>
                    <li 
                      class="profile-nav__item desc"
                      @click="$parent.logout"
                    >
                      <b> {{ $t('Log out') }}</b>
                    </li>
                  </ul>
                </div>
                <div class="profile-modal-right">
                  <div
                    v-if="activeNavItem == 0"  
                    class="profile-modal-content"
                  >
                    <div class="desc desc-main">
                      <b>{{ $t('Basic information') }}</b>
                    </div>
                    <div class="input-field">
                      <div class="input-container">
                        <div class="desc">
                          {{ $t('Email:') }}
                        </div>
                        <input
                          v-model="email"
                          type="email" 
                          readonly
                        >
                      </div>
                      <div class="desc">
                        {{ $t('Your e-mail address is used for important communications.') }}
                      </div>
                    </div>
                    <div class="input-field">
                      <div class="input-container">
                        <div class="desc">
                          {{ $t('Steam trade link:') }}
                        </div>
                        <input
                          v-model="steamLink"
                          type="text" 
                        >
                      </div>
                      <div class="desc">
                        {{ $t('Make sure your Steam inventory is set to public.') }}
                      </div>
                      <button 
                        class="button" 
                        @click="updateSteamLink"
                      >
                        <span>
                          {{ $t('Update') }}
                        </span>
                      </button>
                      <div class="errors">
                        <transition name="fade">
                          <div 
                            v-if="profileUpdateErrorMessaage"
                            class="desc red" 
                          >
                            {{ profileUpdateErrorMessaage }}
                          </div>
                        </transition>
                        <transition name="fade">
                          <div 
                            v-if="profileUpdateSuccessMessaage"
                            class="desc green" 
                          >
                            {{ profileUpdateSuccessMessaage }}
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="activeNavItem == 1"  
                    class="profile-modal-content profile-modal-content-1"
                  >
                    <div class="desc desc-main">
                      <b>{{ $t('Billing information') }}</b>
                    </div>
                    <div class="input-field">
                      <div class="input-container">
                        <div class="desc">
                          {{ $t('First name') }}
                        </div>
                        <input 
                          v-model="name"
                          type="text" 
                          placeholder="First name" 
                        >
                      </div>
                      <div class="input-container">
                        <div class="desc">
                          {{ $t('Last name') }}
                        </div>
                        <input 
                          v-model="surname"
                          type="text" 
                          placeholder="Last name" 
                        >
                      </div>
                      <div class="input-container">
                        <div class="desc">
                          {{ $t('E-mail') }}
                        </div>
                        <input
                          v-model="email"
                          type="email" 
                          placeholder="E-mail" 
                        >
                      </div>
                      <div class="input-container">
                        <div class="desc">
                          {{ $t('Phone') }}
                        </div>
                        <input 
                          v-model="phone"
                          type="tel" 
                          placeholder="Phone" 
                        >
                      </div>
                    </div>
                    <div class="input-field">
                      <div 
                        v-if="countryOptions"
                        class="input-container" 
                      >
                        <div class="desc">
                          {{ $t('Country') }}
                        </div>
                        <select v-model="country">
                          <option 
                            value="" 
                            disabled 
                            selected
                          >
                            {{ $t('Countries') }}  
                          </option>
                          <option 
                            v-for="item in countryOptions" 
                            :key="item.id"
                            :value="item"
                          >
                            {{ item.title }}
                          </option>
                        </select>
                      </div>
                      <div class="input-container">
                        <div class="desc">
                          {{ $t('City') }}
                        </div>
                        <input 
                          v-model="city"
                          type="text" 
                          placeholder="City" 
                        >
                      </div>
                      <div class="input-container">
                        <div class="desc">
                          {{ $t('ZIP Code') }}
                        </div>
                        <input 
                          v-model="zipCode"
                          type="text" 
                          placeholder="ZIP Code" 
                        >
                      </div>
                      <div class="input-container">
                        <div class="desc">
                          {{ $t('Address') }}
                        </div>
                        <input 
                          v-model="address"
                          type="text" 
                          placeholder="Address" 
                        >
                      </div>
                    </div>
                    <button 
                      class="button" 
                      @click="updateBillingInfo"
                    >
                      <span>
                        {{ $t('Save') }}
                      </span>
                    </button>
                    <div class="errors">
                      <transition name="fade">
                        <div 
                          v-if="profileUpdateErrorMessaage"
                          class="desc red" 
                        >
                          {{ profileUpdateErrorMessaage }}
                        </div>
                      </transition>
                      <transition name="fade">
                        <div 
                          v-if="profileUpdateSuccessMessaage"
                          class="desc green" 
                        >
                          {{ profileUpdateSuccessMessaage }}
                        </div>
                      </transition>
                    </div>
                  </div>
                  <div
                    v-if="activeNavItem == 2"  
                    class="profile-modal-content"
                  >
                    <div class="desc desc-main">
                      <b>{{ $t('Transaction history') }}</b>
                    </div>
                    <div 
                      v-if="$parent.depositHistory"
                      class="profile-table" 
                    >
                      <div class="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th class="desc">
                                {{ $t('Date') }}
                              </th>
                              <th class="desc">
                                {{ $t('NOTE') }}
                              </th>
                              <th class="desc">
                                {{ $t('Status') }}
                              </th>
                              <th class="desc">
                                {{ $t('AMOUNT') }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr 
                              v-for="(item,i) in $parent.depositHistory" 
                              :key="i"
                            >
                              <td class="desc">
                                {{ $parent.formatDate(item.created_at) }}
                              </td>
                              <td class="desc">
                                <span>{{ $t('Top up balance') }}</span>
                              </td>
                              <td class="desc">
                                <span>{{ item.status }}</span>
                              </td>
                              <td class="desc">
                                <b>
                                  {{ $parent.currency }} {{ item.sum }}
                                </b>  
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="activeNavItem == 3"  
                    class="profile-modal-content"
                  >
                    <div class="desc desc-main">
                      <b>{{ $t('Item history') }}</b>
                    </div>
                    <div 
                      v-if="$parent.orderHistory"
                      class="profile-table" 
                    >
                      <div class="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th class="desc">
                                {{ $t('Date') }}
                              </th>
                              <th class="desc">
                                {{ $t('ITEM') }}
                              </th>
                              <th class="desc">
                                {{ $t('AMOUNT') }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr 
                              v-for="(item,i) in $parent.orderHistory" 
                              :key="i"
                            >
                              <td class="desc">
                                {{ $parent.formatDate(item.created_at) }}
                              </td>
                              <td class="td-product">
                                <img 
                                  :src="$parent.imgDomain + item.img_url" 
                                  class="img"
                                >
                                <span>{{ item.title }}</span>
                              </td>
                              <td class="desc">
                                <b>
                                  {{ $parent.currency }} {{ item.sum }}
                                </b>  
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="activeNavItem == 4"  
                    class="profile-modal-content profile-modal-content-4"
                  >
                    <div class="desc desc-main">
                      <b>{{ $t('Favorite items') }}</b>
                      <span 
                        class="clear-desc" 
                        @click="$parent.clearFavorites"
                      >
                        {{ $t('Clear list') }}
                      </span>
                    </div>
                    <div 
                      v-if="$parent.favList && $parent.favList.length"
                      class="list products"
                    >
                      <div 
                        v-for="(item, i) in $parent.favList" 
                        :key="i"
                        class="item" 
                      >
                        <ProductCard 
                          :categories="[]" 
                          :item="item" 
                          :add-to-cart-chosen-item="addToCartChosenItem" 
                          :slider-is-clickble="true" 
                          :currency="currency" 
                          :favorite-success-message="favoriteSuccessMessage"
                          :favorite-success-id="favoriteSuccessId"
                          :is-auth="$parent.isAuth"
                          @goToProduct="goToProduct" 
                          @addToCart="addToCart"
                          @toggleFavorite="toggleFavorite"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from '../components/ProductCard.vue'

export default {
  name: 'ProfileModal',
  components: {
    ProductCard
  },
  props: {
    orderHistory: {
      type: [Array, String],
      required: true
    },
    profileData: {
      type: Object,
      required: true
    },
    favoriteSuccessId: {
			type: [String, Number],
			required: true
		},
    favoriteSuccessMessage: {
			type: String,
			required: true
		},
    addToCartChosenItem: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
  },
  data: function() {
    return {
      totalSum: '',
      nav: [
        'Basic information',
        'Billing information',
        'Transaction history',
        'Item history',
        'Favorite items'
      ],
      activeNavItem: 0,
      steamLink: '',
      name: '',
      surname: '',
      email: '',
      phone: '',
      country: '',
      city: '',
      address: '',
      zipCode: '',
      steamId: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      countryOptions: [],
      countryId: null
    }
  },
  watch: {
    orderHistory() {
      this.totalSum = this.getTotalSum(this.orderHistory);
      this.activeNavItem = 3;
    },
    profileData() {
      this.email = this.profileData.email;
      this.name = this.profileData.name;
      this.surname = this.profileData.surname;
      this.phone = this.profileData.phone;
      this.city = this.profileData.city;
      this.address = this.profileData.address;
      this.zipCode = this.profileData.zip;
      this.steamId = this.profileData.steamId;
      this.countryId = this.profileData.country;
      this.steamLink = this.profileData.steam_profile_url;
      
      this.getCounties();
    }
  },
  mounted() {
    this.$parent.getProfile();
    this.$parent.getFavorites();
    this.$parent.getOrderHistory();
    this.$parent.getDepositHistory();
  },
  methods: {
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
          this.country = res.data.payload.find(item => item.id == this.countryId);
      })
      .catch((res) => {
      })
    },
    setActiveNavItem(i) {
      this.activeNavItem = i;
    },
    getTotalSum(items) {
      let totalSum = 0;
      items.forEach(item => {
        totalSum += item.sum;
      });
      return totalSum.toFixed(2);
    },
    updateSteamLink() {
      let self = this;
      let steamLink = {
        "url": this.steamLink,
      }
      this.$http.put(process.env.VUE_APP_API + 'user/steam-profile-url', steamLink)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            self.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch(() => {
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          self.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updateBillingInfo() {
      let self = this;
      let profileData = {
        "email": this.email,
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "city": this.city,
        "address": this.address,
        "postCode": this.zipCode,
        "country": this.country ? this.country.id : '',
        "steamId": 'sf',
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            self.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.profileUpdateErrorMessaage = firstErrors[key];
                setTimeout(() => {
                  self.profileUpdateErrorMessaage = '';
                }, 3000);
            }
          }
        } else {
          this.profileUpdateErrorMessaage = res.response.data.message;
          setTimeout(() => {
            self.profileUpdateErrorMessaage = '';
          }, 3000);
        }
      })
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    toggleFavorite(item) {
			this.$emit('toggleFavorite', item);
		}
  }
}
</script>