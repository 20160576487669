<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <div class="nav-container">
            <ul class="nav">
              <template v-for="item in $parent.textPageList">
                <li  
                  v-if="item.title"
                  :key="item.id" 
                  class="nav__item desc small"
                >
                  <a 
                    class="desc"
                    @click="$parent.goToPage(item)"
                  >
                    <b>{{ item.title }}</b>
                  </a>
                </li>
              </template>
              <li class="nav__item desc small">
                <router-link 
                  to="/"
                  class="desc"
                >
                  <b>Home</b>
                </router-link>
              </li>
              <li class="nav__item desc small">
                <router-link 
                  to="/product-list" 
                  class="desc"
                >
                  <b>Collection</b>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="center">
          <div class="images">
            <img 
              src="./../images/dotaLogo.svg" 
              class="img"
            >
            <div class="desc small">
              {{ $t('and') }}
            </div>
            <img 
              src="./../images/steamLogo.svg" 
              class="img"
            >
          </div>
          <div class="desc small">
            {{ $t('are trademarks and/or registered trademarks of Valve Corporation. All rights reserved by Valve.') }}
          </div>
          <div class="desc small">
            {{ $t('AegisSkins.com is not affiliated with Valve Corporation. All game images and names are used solely for identification purposes.') }}
          </div>
        </div>
        <div class="right">
          <div class="info">
            <div class="row">
              <div class="desc small">
                {{ $t('Requisites') }}
              </div>
              <div class="desc link small">
                {{ $t($parent.footerRequisites) }}
              </div>
            </div>
            <div class="row">
              <div class="desc small">
                {{ $t('Support') }}
              </div>
              <div class="desc link small">
                {{ $t($parent.footerSupportEmail) }}
              </div>
            </div>
          </div>
          <ul class="socials">
            <li 
              v-for="link in $parent.socials" 
              :key="link.image" 
              class="socials__item"
            >
              <a 
                :href="link.link" 
                class="desc" 
                target="_blank"
              >
                <img 
                  :src="link.img" 
                  :alt="link.link" 
                  class="img" 
                >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright-section">
      <div class="wrapper">
        <div class="copyright">
          <div 
            v-if="$parent.footerCopyright"
            class="desc" 
          >
            © {{ new Date().getFullYear() }} {{ $parent.footerCopyright }}
          </div>
        </div>
        <div class="payment-list">
          <div class="item">
            <img 
              src="./../images/visa.svg" 
              class="img"
            >
          </div>
          <div class="item mc">
            <img 
              src="./../images/mc_symbol.svg" 
              class="img"
            >
          </div>
          <!-- <div class="item">
            <img 
              src="./../images/3ds.svg" 
              class="img"
            >
          </div> -->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	mounted() {
  },
	methods: {
	}
}
</script>
