<template>
  <main class="main page-inside text-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="page-inside-top">
          <transition name="fade">
            <div class="wrapper" v-if="$parent.textPageTitle">
              <div class="title">{{$parent.textPageTitle}}</div>
              <div class="desc" v-html="$parent.textPageHeader"></div>
              <div class="desc" v-html="$parent.textPageContent"></div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'TextPage',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
    this.$parent.getTextPageContent(this.$route.params.id.replace(/\//g, '').replace(/ /g, '-'));
  },
  methods: {
    
  }
}
</script>