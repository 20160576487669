<template>
  <div 
    id="app"
    :class="['app ' + $route.name]"
  >
    <transition name="fade">
      <div 
        v-if="pageLoaded"
        id="page" 
        class="page" 
      >
        <transition name="fade">
          <Menu 
            v-if="menuVisible" 
            :is-auth="isAuth" 
            :cart-contents="cartContents" 
            :currency-code="currencyCode" 
            @scrollToSection="scrollToSection"
            @changeCurrency="changeCurrency"
          />
        </transition>
        <div 
          :class="['mob menu-icon', {'active': menuVisible}]" 
          @click="toggleMenu" 
        >
          <span />
          <span />
          <span />
        </div>
        <div class="header-container">
          <Header 
            :is-auth="isAuth" 
            :searched-product-text="searchedProductText" 
            :cart-contents="cartContents" 
            :currency-code="currencyCode" 
            :app-balance="appBalance"
            @scrollToSection="scrollToSection"
            @searchSubmit="searchSubmit" 
            @changeCurrency="changeCurrency" 
          />
        </div>
        <router-view 
          :is-auth="isAuth" 
          :products-filtered-by-search="productsFilteredBySearch" 
          :currency="currency" 
          :quality-options="qualityOptions"
          :type-options="typeOptions"
          :hero-options="heroOptions"
          :text-page-header="textPageHeader"
          :text-page-content="textPageContent"
          :add-to-cart-chosen-item="addToCartChosenItem" 
          :favorite-success-message="favoriteSuccessMessage"
          :favorite-success-id="favoriteSuccessId"
          :currency-code="currencyCode" 
          :fav-list="favList"
          @addToCart="addToCart" 
          @formatDate="formatDate" 
          @goToProduct="goToProduct" 
          @toggleFavorite="toggleFavorite"
          @login="login" 
          @forgotPass="forgotPass" 
          @registration="registration" 
        />
        <div class="footer-container">
          <Footer />
        </div>
        <transition name="fade">
          <Login 
            v-if="signInModalIsVisible" 
            @closeSignIn="closeSignIn"
            @openSignUp="openSignUp" 
            @login="login" 
            @forgotPass="forgotPass"
          />
        </transition>
        <transition name="fade">
          <Registration 
            v-if="signUpModalIsVisible" 
            @closeSignUp="closeSignUp" 
            @openSignIn="openSignIn" 
            @registration="registration" 
          />
        </transition>
        <transition name="fade">
          <Cart 
            v-if="cartIsVisible" 
            @setError="setError" 
          />
        </transition>
        <transition name="fade">
          <ProductModal 
            v-if="productModalIsVisible" 
            :currency="currency" 
            :product-page-item="productPageItem"
            @addToCart="addToCart" 
            @goToProduct="goToProduct" 
            @toggleFavorite="toggleFavorite"
          />
        </transition>
        <transition name="fade">
          <ProfileModal 
            v-if="profileModalIsVisible" 
            :currency="currency" 
            :order-history="orderHistory"
            :profile-data="profileData"
            :add-to-cart-chosen-item="addToCartChosenItem" 
            :favorite-success-message="favoriteSuccessMessage"
            :favorite-success-id="favoriteSuccessId"
            @addToCart="addToCart" 
            @toggleFavorite="toggleFavorite"
            @goToProduct="goToProduct" 
          />
        </transition>
        <transition name="fade">
          <TopUpModal
            v-if="topupModalIsVisible"
            :profile-data="profileData"
            @setError="setError"  
          />
        </transition>
        <transition name="fade">
          <ContactModal
            v-if="contactModalIsVisible"
            @setError="setError"  
          />
        </transition>
        <div 
          v-if="cookesModalIsVisible"
          class="modal-overlay" 
        >
          <div class="cookies-modal">
            <div class="title">
              {{ $t('Our Cookie Practices') }}
            </div>
            <div class="desc">
              To ensure our website operates seamlessly and enhances your visit, we utilize cookies. Essential cookies, which are crucial for basic site functionality, are set automatically. Additionally, we use non-essential cookies that allow us to customize your experience, analyze site interactions, and provide advertising that aligns with your interests. For a deeper understanding of our cookie practices, please visit our <a 
                class="desc"
                @click="goToPage('cookie')" 
              >
                {{ $t('Cookie Policy') }}
              </a>.
            </div>
            <button 
              class="button blue" 
              @click="acceptCookies"
            >
              {{ $t('Ok') }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import {loadScriptsFromString} from "@/utils/load_scripts";
import Login from './components/Login.vue'
import Registration from './components/Registration.vue'
import Cart from './components/Cart.vue'
import TopUpModal from './components/TopUp.vue'
import ContactModal from './components/ContactModal.vue'
import ProductModal from './components/ProductModal.vue'
import ProfileModal from './components/ProfileModal.vue'
import {scroller} from 'vue-scrollto/src/scrollTo'
import {mapActions, mapState} from 'vuex';



export default {
  name: 'App',
  components: {
    Header,
    Menu,
    Footer,
    Registration,
    Login,
    Cart,
    TopUpModal,
    ContactModal,
    ProductModal,
    ProfileModal
  },
  data: function() {
    return {
     pageLoaded: false,
     menuVisible: false,
     nav: [
      {
        'title': 'HOME',
        'link': '/home'
      },
      {
        'title': 'C2 MARKET',
        'link': '/product-list/all'
      }
     ],
      isAuth: false,
      error: '',
      successMessage: '',
      favoriteSuccessMessage: '',
      favoriteSuccessId: '',
      orderHistory: [],
      orderToView: [],
      addToCartChosenItem: '',
      recommendedProducts: [],
      saleProducts: [],
      cartContents: [],
      totalSum: '0.00',
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      productPageItem: {},
      similarProducts: '',
      imgDomain: '',
      textPageList: '',
      hostname: '',
      footerRequisites: '',
      footerSupportEmail: '',
      footerCopyright: '',
      typeOptions: [],
      qualityOptions: [],
      heroOptions: [],
      currency: '',
      currencyCode: '',
      currencyOptions: '',
      signInModalIsVisible: false,
      signUpModalIsVisible: false,
      cartIsVisible: false,
      topupModalIsVisible: false,
      contactModalIsVisible: false,
      depositHistory: [],
      searchedProductText: '',
      productsFilteredBySearch: '',
      cookesModalIsVisible: false,
      socials: '',
      productModalIsVisible: false,
      latestSoldProducts: [],
      appBalance: '',
      profileModalIsVisible: false,
      profileData: {},
      favList: []
    }
  },
  computed: {
    ...mapState({
      currentLanguage: state => state.app.currentLanguage,
    }),
  },
  watch: {
    currencyCode() {
      if (this.productPageItem) {
        this.getProductPageContent(this.productPageItem.id);
      }
      if (this.isAuth) {
        this.getCartContents();
        this.getProfile();
      }
      this.latestSoldProducts = [];
      this.getLatestSoldProducts();
      
    },
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
    }
  },
  mounted: async function() {
    this.cookiesModal();
    this.hostname = this.getDomainName(window.location.hostname);
    await this.fetchLanguages();
    await this.getCurrencies();
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();

    self.getGoogleTagManagerSnippet();
    
    self.$http.get(process.env.VUE_APP_API + 'is-auth')
    .then((res) => {
        console.log('auth: ' + res.data.authenticated);
        self.isAuth = res.data.authenticated

        if (res.data.authenticated) {
          self.getProfile();
          self.getCartContents();
        }
    })
    .catch((res) => {
    })
    this.getCategoryOptions();
    this.getTextPages();
    this.getSocials();
  },
  methods: {
    getFavorites() {
      this.$http.get(process.env.VUE_APP_API + 'favorites/contents')
      .then((res) => {
          this.favList = res.data.payload;
      })
      .catch((res) => {
      })
    },
    clearFavorites() {
      this.$http.post(process.env.VUE_APP_API + 'favorites/clear')
      .then((res) => {
        this.getFavorites();
      })
      .catch((res) => {
      })
    },
    closeProfileModal(){
      this.profileModalIsVisible = false;
      document.body.style.overflow = "auto";
    },
    openProfileModal(){
      this.profileModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.appBalance = res.data.balance;
          this.profileData = res.data;
      })
      .catch((res) => {
      })
    },
    getLatestSoldProducts() {
      this.$http.get(process.env.VUE_APP_API + 'items/last-sold?category=dota2&limit=6&random=true')
      .then((res) => {
        this.latestSoldProducts = res.data.payload;
      })
      .catch((res) => {
      })
    },
    scrollToSection(section) {
      if ((this.$route.name != 'Home') && section != 'page') {
        this.$router.push({path: '/'});
      } else {
        this.closeMenu();
      }

      setTimeout(()=> {
        const firstScrollTo = scroller()
        firstScrollTo('#' + section, 1000)
      }, 500)
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');

      if (!cookiesAccepted) {
          this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.cookesModalIsVisible = false;
    },
    clearSearch() {
      this.searchedProductText = '';
    },
    searchSubmit(text) {
      this.searchedProductText = text;   
      this.productsFilteredBySearch = text;
      if (!this.$route.fullPath.includes('product-list/search')) {
        this.$router.push({ path: '/product-list/search'});
      }  
    }, 
    getCurrencies() {
      this.$http.get(process.env.VUE_APP_API + 'currencies')
      .then((res) => {
          const newCurrencies = res.data.payload.map(({
            code: text,
            value: id,
            symbol
          }) => ({
            text,
            id,
            symbol
          }));
          this.currencyOptions = newCurrencies;

          if (localStorage.getItem("currency")) {
            this.currencyCode = localStorage.getItem("currency")
          } else {
            this.currencyCode = this.currencyOptions[0].text;
          }
          this.checkCurrency();
          
      })
      .catch(() => {
       
      })
    },
    changeCurrency(item) {
      this.currencyCode = item;
      this.checkCurrency();
    },
    checkCurrency() {
      this.currency = this.currencyOptions.find((item) => item.text == this.currencyCode).symbol;
    },
    getCategoryOptions() {
      this.$http.get(process.env.VUE_APP_API + 'items/filter-data')
      .then((res) => {
        this.typeOptions = res.data.payload.types;
        this.qualityOptions = res.data.payload.qualities;
        this.heroOptions = res.data.payload.heroes;

      })
      .catch((res) => {
      })
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    clearError() {
      this.error = '';
    },
    setError(error) {
      this.error = error;
    },
    submitLoginSteam() {
      window.location.replace(process.env.VUE_APP_DOMAIN + "auth/steam/redirect");
    },
    login(data) {
      this.$http.post(process.env.VUE_APP_API + 'login', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.isAuth = true;
          this.getCartContents();
          this.getProfile();
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    forgotPass(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'user/reset-password', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage = 'Success';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((error) => {
        this.error = error.response.data.message
      })
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = 'Password confirmation failed!';
      } else {
        this.$http.post(process.env.VUE_APP_API + 'register', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.clearError();
            self.isAuth = true;
            this.closeSignUp();
            this.getProfile();
          } else {
            this.error = res.data.message
          }
        })
        .catch((res) => {
            this.error = res.response.data.message;
        })
      }
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + 'logout')
      .then(() => {
        if (this.$route.name != 'Home') {
          this.$router.push({ path: '/' })
        }
        this.isAuth = false;
        this.closeProfileModal();
      })
      .catch(() => {
      
      })
    },
    getDepositHistory() {
      this.$http.get(process.env.VUE_APP_API + 'deposit/history')
      .then((res) => {
        this.depositHistory = res.data.payload
      })
      .catch(() => {
      })
    },
    getOrderHistory() {
      this.$http.get(process.env.VUE_APP_API + 'purchase/history')
      .then((res) => {
        this.orderHistory = res.data.payload
      })
      .catch(() => {
      })
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    removeOneFromCart(item, id) {
      let cartItem = {
        'id': id,
        'count': '1'
      }
      if (item.count > 1) {
        cartItem = {
          'id': id,
          'count': item.count - 1
        }
      }
      this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
      .then((res) => {
        this.getCartContents();
      })
      .catch(() => {
      })
      
    },
    clearCart() {
      this.$http.post(process.env.VUE_APP_API + 'cart/clear')
      .then((res) => {
        this.getCartContents();
      })
      .catch(() => {
      })
    },
    removeFromCart(item, id) {
      let cartItem = {
        'id': id
      }

      this.$http.post(process.env.VUE_APP_API + 'cart/remove', cartItem)
      .then((res) => {
        this.getCartContents();
      })
      .catch(() => {
      })
    },
    addToCart(item, id) {
      let self = this;

      let cartItem = {
        'id': id,
        'count': 1
      }

      for(var i = 0; i < this.cartContents.length; i++) {
        if (this.cartContents[i].item_id == id) {
            let cartItemCount = this.cartContents[i].count;
            cartItem = {
              'id': id,
              'count': cartItemCount + 1
            }
            break;
        }
      }

      this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
      .then((res) => {
        this.error = '';
        this.getCartContents();
        self.addToCartChosenItem = cartItem.id;
        self.cartContents = res.data.cart
        setTimeout(()=> {
          self.addToCartChosenItem = '';
        }, 2000)
      })
      .catch((res) => {
        this.error = res.response.data.message;
      })
    },
    toggleFavorite(item) {
      let data = {
        'id': item.id
      }
      if (item.is_user_favorite || this.profileModalIsVisible) {
        this.$http.post(process.env.VUE_APP_API + 'favorites/remove', data)
        .then((res) => {
          let self = this;
          self.favoriteSuccessMessage = 'Removed From Favorites'
          self.favoriteSuccessId = item.id;
          if (self.profileModalIsVisible) {
            self.getFavorites();
          }
          if (self.productModalIsVisible) {
            self.getProductPageContent(item.id);
          }
          setTimeout(()=> {
            self.favoriteSuccessMessage = '';
            self.favoriteSuccessId = '';
          }, 2000)
        })
        .catch((res) => {
          
        })
      } else {
        this.$http.post(process.env.VUE_APP_API + 'favorites/add', data)
        .then((res) => {
          let self = this;
          self.favoriteSuccessMessage = 'Added To Favorites'
          self.favoriteSuccessId = item.id;
          if (self.productModalIsVisible) {
            self.getProductPageContent(item.id);
          }
          setTimeout(()=> {
            self.favoriteSuccessMessage = '';
            self.favoriteSuccessId = '';
          }, 2000)
        })
        .catch((res) => {
          
        })
      }
    },
    getCartContents() {
      this.$http.get(process.env.VUE_APP_API + 'cart/contents')
      .then((res) => {
          this.cartContents = res.data.cart;
          
          let total = 0;
          for (let i in this.cartContents) {
            let newPrice;
            if (this.cartContents[i].count > 1) {
              newPrice = this.cartContents[i].count * parseFloat(this.cartContents[i].item.price);
            } else {
              newPrice = parseFloat(this.cartContents[i].item.price);
            }
            total += newPrice;
          }
          this.totalSum = total.toFixed(2);
      })
      .catch(() => {
      })
    },
    goToProduct(item) {
      this.openProductModal();
      this.getProductPageContent(item.id);
    },
    getProductPageContent(id) {
      this.similarProducts = '';
      if(id) {
        this.$http.get(process.env.VUE_APP_API + 'items/' + id)
        .then((res) => {
          this.productPageItem = res.data.payload;
          this.similarProducts = res.data.payload.similar;
        })
        .catch(() => {
        })
      }
    },
    goToPrivacy() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_privacy) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + this.textPageList[i].title.replace(/\//g, '').replace(/ /g, '-'), params: {data: this.textPageList[i].title.replace(/\//g, '').replace(/ /g, '-')}})
          this.getTextPageContent(pageId);
          this.closeRegister();
        } 
      }
    },
    goToTerms() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_terms) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + this.textPageList[i].title.replace(/\//g, '').replace(/ /g, '-'), params: {data: this.textPageList[i].title.replace(/\//g, '').replace(/ /g, '-')}})
          this.getTextPageContent(pageId);
          this.closeRegister();
        } 
      }
    },
    goToAllowedCountries() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_countries) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + this.textPageList[i].title.replace(/\//g, '').replace(/ /g, '-'), params: {data: this.textPageList[i].title.replace(/\//g, '').replace(/ /g, '-')}})
          this.getTextPageContent(pageId);
        } 
      }
    },
    goToPage(item) {
      this.$router.push({path: '/pages/' + item.title.replace(/\//g, '').replace(/ /g, '-'), params: {data: item.title.replace(/\//g, '').replace(/ /g, '-')}})
      this.getTextPageContent(item.id);
    },
    goToCookiesPage() {
      this.$router.push({path: '/pages/' + 7, params: {data: 7}})
      this.getTextPageContent(7);
    },
    getTextPageContent(id) {
      let newId = '';
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';

      if (/-/.test(id)) {
        this.$http.get(process.env.VUE_APP_API + 'static-pages')
        .then((res) => {
          newId = res.data.payload.filter(item => item.title != null);
          newId = newId.filter(item => item.title.replace(/\//g, '').replace(/ /g, '-') == id)[0].id;
          this.$http.get(process.env.VUE_APP_API + 'static-pages/' + newId)
          .then((res) => {
            this.textPageTitle = res.data.payload.title;
            this.textPageContent = res.data.payload.content;
            this.textPageHeader = res.data.payload.header;
          })
          .catch((res) => {
            if(res.response.status === 401) {
              this.openSignInModal()
            }
          })
        })
      } else {
        newId = id;
        this.$http.get(process.env.VUE_APP_API + 'static-pages/' + newId)
        .then((res) => {
          this.textPageTitle = res.data.payload.title;
          this.textPageContent = res.data.payload.content;
          this.textPageHeader = res.data.payload.header;
        })
        .catch((res) => {
          if(res.response.status === 401) {
            this.openSignInModal()
          }
        })
      }
    },
    getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch(() => {
         
      })
    },
    openTopup() {
      this.topupModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeTopup() {
      this.topupModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    openContact() {
      this.contactModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeContact() {
      this.contactModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    openCart() {
      this.cartIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeCart() {
      this.cartIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    openSignIn() {
      this.signUpModalIsVisible = false;
      this.signInModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeSignIn() {
      this.signInModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    openSignUp() {
      this.signInModalIsVisible = false;
      this.signUpModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeSignUp() {
      this.signUpModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    openProductModal() {
      this.productModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeProductModal() {
      this.productPageItem = '';
      this.productModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    openRecover() {
      this.$router.push({path: '/recover'});
    },
    getGoogleTagManagerSnippet() {
      this.$http.get(process.env.VUE_APP_API + 'settings')
      .then((res) => {
        this.footerRequisites = res.data.payload.requisites;
        this.footerSupportEmail = res.data.payload.support_email;
        this.footerCopyright = res.data.payload.copyright;
        const snippet = res.data.payload.google_tag;
        if (snippet) {
          loadScriptsFromString(snippet);
        }
      })
      .catch((error) => {
        console.error("Error fetching Google Tag Manager snippet:", error);
      });
    },
    getSocials() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
      .then((res) => {
        this.socials = res.data.payload;
      })
      .catch((error) => {
        console.error(error);
      });
    },
    ...mapActions('app', ['fetchLanguages'])
  },
}
</script>
