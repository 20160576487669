<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="page-inside-wrapper">
          <div class="filters">
            <div class="filters-wrapper">
              <div class="filters-list">
                <div :class="['filters__item', {'active': activeFilterDropdown == 'type'}]">
                  <div class="desc">
                    <img 
                      :class="['clear', {'active': type && type.length}]" 
                      src="./../images/close.svg"
                      @click="resetType"
                    >
                    <span>{{ $t('Type') }}</span>
                    <div 
                      class="toggle" 
                      @click="setActiveFilterDropdown('type')"
                    />
                  </div>
                </div>
                <div :class="['filters__item', {'active': activeFilterDropdown == 'price'}]">
                  <div class="desc">
                    <img 
                      :class="['clear', {'active': barMinValue != 0 || barMaxValue != 1200}]" 
                      src="./../images/close.svg"
                      @click="resetPrice"
                    >
                    <span>{{ $t('Price') }}</span>
                    <div 
                      class="toggle" 
                      @click="setActiveFilterDropdown('price')"
                    />
                  </div>
                </div>
                <div :class="['filters__item', {'active': activeFilterDropdown == 'quality'}]">
                  <div class="desc">
                    <img 
                      :class="['clear', {'active': quality && quality.length}]" 
                      src="./../images/close.svg"
                      @click="resetQuality"
                    >
                    <span>{{ $t('Rarity') }}</span>
                    <div 
                      class="toggle" 
                      @click="setActiveFilterDropdown('quality')"
                    />
                  </div>
                </div>
                <div :class="['filters__item', {'active': activeFilterDropdown == 'hero'}]">
                  <div class="desc">
                    <img 
                      :class="['clear', {'active': hero && hero.length}]" 
                      src="./../images/close.svg"
                      @click="resetHero"
                    >
                    <span>{{ $t('Hero') }}</span>
                    <div 
                      class="toggle" 
                      @click="setActiveFilterDropdown('hero')"
                    />
                  </div>
                </div>
                <div class="filters__item">
                  <Select2 
                    ref="sortSelect"
                    v-model="sort"
                    class="select2 single currency-select" 
                    :options="sortOptions"
                    placeholder="Highest price first"
                    @select="selectSort($event)"
                  />
                </div>
              </div>
              <div class="filters-preview">
                <div
                  v-if="typeOptions.length && activeFilterDropdown == 'type'"
                  class="type-list" 
                >
                  <label 
                    v-for="(item, i) in typeOptions" 
                    :key="i"
                    class="chekbox-label" 
                  >
                    <div class="chekbox-label-wrapper">
                      <input 
                        v-model="type"
                        type="checkbox" 
                        :value="item" 
                      >
                      <div class="checkbox" />
                      <span class="title">{{ item }}</span>
                    </div>
                  </label>
                </div>
                <div
                  v-if="qualityOptions.length && activeFilterDropdown == 'quality'"
                  class="type-list" 
                >
                  <label 
                    v-for="(item, i) in qualityOptions" 
                    :key="i"
                    class="chekbox-label" 
                  >
                    <div class="chekbox-label-wrapper">
                      <input 
                        v-model="quality"
                        type="checkbox" 
                        :value="item" 
                      >
                      <div class="checkbox" />
                      <span class="title">{{ item }}</span>
                    </div>
                  </label>
                </div>
                <div
                  v-if="heroOptions.length && activeFilterDropdown == 'hero'"
                  class="type-list" 
                >
                  <label 
                    v-for="(item, i) in heroOptions" 
                    :key="i"
                    class="chekbox-label" 
                  >
                    <div class="chekbox-label-wrapper">
                      <input 
                        v-model="hero"
                        type="checkbox" 
                        :value="item" 
                      >
                      <div class="checkbox" />
                      <span class="title">{{ item }}</span>
                    </div>
                  </label>
                </div>
                <div
                  v-if="activeFilterDropdown == 'price'"
                  class="type-list price-range-container" 
                >
                  <div class="price-range">
                    <div class="val-container">
                      <div class="val min">
                        <div class="desc desc-currency">
                          {{ $parent.currency }}
                        </div>
                        <div class="desc desc-name">
                          {{ $t('min') }}
                        </div>
                        <input 
                          v-model="barMinValue"
                          type="number" 
                        >
                      </div>
                      <div class="desc">
                        -
                      </div>
                      <div class="val max">
                        <div class="desc desc-currency">
                          {{ $parent.currency }}
                        </div>
                        <div class="desc desc-name">
                          {{ $t('max') }}
                        </div>
                        <input 
                          v-model="barMaxValue"
                          type="number" 
                        >
                      </div>
                    </div>
                  </div>
                  <div class="multi-range-slider-container">
                    <MultiRangeSlider
                      :min="0"
                      :max="1200"
                      :step="10"
                      :ruler="false"
                      :label="false"
                      :range-margin="0"
                      :min-value="barMinValue"
                      :max-value="barMaxValue"
                      @input="updateRangeValues"
                    />
                    <div class="val">
                      <div class="desc desc-name">
                        {{ $t('min') }}
                      </div>
                      <div class="desc desc-name">
                        {{ $t('max') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <div class="product-container">
              <transition name="fade">
                <div 
                  v-if="productList && productList.length"
                  class="list products"
                >
                  <div 
                    v-for="(item, i) in productList" 
                    :key="i"
                    class="item" 
                  >
                    <ProductCard 
                      :categories="[]" 
                      :item="item" 
                      :add-to-cart-chosen-item="addToCartChosenItem" 
                      :slider-is-clickble="true" 
                      :currency="currency" 
                      :favorite-success-message="favoriteSuccessMessage"
                      :favorite-success-id="favoriteSuccessId"
                      :is-auth="$parent.isAuth"
                      @goToProduct="goToProduct" 
                      @addToCart="addToCart"
                      @toggleFavorite="toggleFavorite"
                    />
                  </div>
                </div>
              </transition>
              <transition name="fade">
                <div
                  v-if="productList && !productList.length"
                  class="title small no-products" 
                >
                  {{ $t('No products!') }}
                </div>
              </transition>
            </div>
            <pagination 
              v-if="totalProducts"
              v-model="activePage" 
              :records="totalProducts" 
              :per-page="18" 
              @paginate="changePage"
            />
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Pagination from 'vue-pagination-2';
import ProductCard from '../components/ProductCard.vue';
import Select2 from 'vue3-select2-component';
import MultiRangeSlider from "multi-range-slider-vue";


export default {
  name: 'ProductListPage',
  components: {
    Pagination,
    ProductCard,
    Select2,
    MultiRangeSlider
  },
  props: {
    favoriteSuccessId: {
			type: [String, Number],
			required: true
		},
    favoriteSuccessMessage: {
			type: String,
			required: true
		},
		addToCartChosenItem: {
			type: [String, Object, Number],
			required: true
		},
		currency: {
			type: String,
			required: true
		},
		productsFilteredBySearch: {
			type: [String, Array],
			required: true
		},
    qualityOptions: {
			type: Array,
			required: true
		},
    typeOptions: {
			type: Array,
			required: true
		},
    heroOptions: {
			type: Array,
			required: true
		},
    currencyCode: {
      type: String,
      required: true
    },
    favList: {
			type: Array,
			required: true
		},
	},
  data: function() {
    return {
      imgDomain: '',
      sort: 'desc',
      category: '',
      quality: [],
      type: [],
      hero: [],
      activePage: 1,
      totalProducts: null,
      sortOptions: [
        {
          id: 'desc',
          text: 'Highest price first'
        }, 
        {
          id: 'asc',
          text:'Lowest price first'
        }
      ],
      barMinValue: 0,
      barMaxValue: 1200,
      productList: '',
      activeFilterDropdown: '',
      dragged: false
    }
  },
  computed: {
    newCategoryOptions() {
      let newCategories = [...this.$parent.categoryOptions];
      newCategories.unshift('All types');
      return newCategories;
    },
  },
  watch: {
      barMinValue: function() {
        this.activePage = 1;
        this.filterProducts();
      },
      barMaxValue: function() {
        this.activePage = 1;
        this.filterProducts();
      },
      quality: function() {
        this.activePage = 1;
        this.filterProducts();
      },
      type: function() {
        this.activePage = 1;
        this.filterProducts();
      },
      hero: function() {
        this.activePage = 1;
        this.filterProducts();
      },
      productsFilteredBySearch: function() {
        this.activePage = 1;
        this.filterProducts();
      },
      currencyCode() {
        this.filterProducts();
      },
      favoriteSuccessMessage() {
        this.filterProducts();
      },
      favList() {
        this.filterProducts();
      },
  },
  beforeDestroy() {
    // Cleanup
    $(this.$refs.mySelect.$el).off('select2:open', this.onSelectOpen);
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.filterProducts();
    $(this.$refs.sortSelect.$el).on('select2:open', this.onSelectOpen);

    addEventListener("mouseup", () => {
      if (this.dragged) {
        this.filterProducts();
        this.dragged = false;
      }
    });
    addEventListener("touchend", () => {
      if (this.dragged) {
        this.filterProducts();
        this.dragged = false;
      }
    });
  },
  methods: {
    onSelectOpen() {
      this.activeFilterDropdown = '';
    },
    updateRangeValues(e) {
      this.activePage = 1;
      this.barMinValue = e.minValue;
      this.barMaxValue = e.maxValue;

      this.dragged = true;
    },
    setActiveFilterDropdown(item) {
      if (this.activeFilterDropdown == item) {
        this.activeFilterDropdown = '';
      } else {
        this.activeFilterDropdown = item;
      }
      
    },
    selectSort(event) {
      this.activePage = 1;
      this.sort = event.id;
      this.filterProducts();
    },
    resetType() {
      this.type = [];
    },
    resetQuality() {
      this.quality = [];
    },
    resetHero() {
      this.hero = [];
    },
    resetPrice() {
      this.barMinValue = 0;
      this.barMaxValue = 1200;
    },
    filterProducts() {
     
      let addCategory = '';
      if (this.type != '') {
        addCategory = '&type=' + this.type
      } else {
        addCategory = '';
      }

      let addQuality = '';
      if (this.quality != '') {
        addQuality = '&quality=' + this.quality
      } else {
        addQuality = '';
      }

      let addHero = '';
      if (this.hero != '') {
        addHero = '&heroes=' + this.hero
      } else {
        addHero = '';
      }

      let addPrice = '&price_from=' + this.barMinValue + '&price_till=' + this.barMaxValue;

      let addSort = '&sort=' + this.sort

      let addPage = '&page=' + this.activePage
      
      this.$http.get(process.env.VUE_APP_API + 'items/list?limit=15&category=dota2' + addCategory + addHero + addQuality + addPrice + addSort + addPage)
      .then((res) => {
        this.productList = res.data.payload
        this.totalProducts = res.data.meta.total
      })
      .catch(() => {
      })
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    toggleFavorite(item) {
			this.$emit('toggleFavorite', item);
		}
  }
}
</script>